// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {} // xs: 0, // sm: 576, // md: 768, // lg: 992, // xl: 1200

// export const $themeImages = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: '曠世國際', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: 'https://www.home.twhost.com.tw/img/LOGO-dark01.png', // Will update logo in navigation menu (Branding)
    branch: '', // 1.註掉branch 2.axios路徑改相對 3.store app baseUrl改相對
    imagePrefix: 'twhost',
    backEndUrl: '/user/', // http://localhost:8082
  },
  system: {
    language: 'zh-tw',
  },
  layout: {
    // isRTL: false,
    // skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    // type: 'vertical', // vertical, horizontal
    // contentWidth: 'full', // full, boxed
    // menu: {
    //   hidden: false,
    //   isCollapsed: false,
    // },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'static', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    // footer: {
    //   type: 'static', // static, sticky, hidden
    // },
    // customizer: true,
    // enableScrollToTop: true,
  },
}
