export default [
  {
    path: '/domain',
    name: 'domain',
    component: () => import('@/views/domain/Domain.vue'),
    meta: {
      pageTitle: 'Domain',
    },
  },
]
