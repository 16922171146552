import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    app: {
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      branch: $themeConfig.app.branch,
      imagePrefix: $themeConfig.app.imagePrefix,
      backEndUrl: $themeConfig.app.backEndUrl,
    },
    system: {
      language: $themeConfig.system.language,
    },
    layout: {
    //   isRTL: $themeConfig.layout.isRTL,
    //   skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
    //   type: $themeConfig.layout.type,
    //   contentWidth: $themeConfig.layout.contentWidth,
    //   menu: {
    //     hidden: $themeConfig.layout.menu.hidden,
    //   },
    //   navbar: {
    //     type: $themeConfig.layout.navbar.type,
    //     backgroundColor: $themeConfig.layout.navbar.backgroundColor,
    //   },
    //   footer: {
    //     type: $themeConfig.layout.footer.type,
    //   },
    },
  },
  getters: {},
  mutations: {
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
  },
  actions: {},
}
