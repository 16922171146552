import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import api from './api'
import app from './app'
import appConfig from './app-config'
import apiCart from './api/cart'
// import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    app,
    appConfig,
    'api-cart': apiCart,
    // verticalMenu,
  },
  strict: process.env.DEV,
})
