import { $themeConfig } from '@themeConfig'

const { branch } = $themeConfig.app

export default {
  // Endpoints
  loginEndpoint: `${branch}/login`,
  registerEndpoint: `${branch}/register`,
  refreshEndpoint: '/auth/refreshToken',
  logoutEndpoint: `${branch}/logout`,
  checkEndpoint: `${branch}/check`,
  forgetPasswordEndpoint: `${branch}/forgot_password`,
  resetPasswordEndpoint: `${branch}/reset_password`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
