<template>
  <div
    id="app"
    ref="app"
    class="h-100"
  >
    <!-- <Navbar2 class="navbar-header">
      <template v-slot:nav-bar-language>
        <div>
          <span class="mr-1">
            個人資料
          </span>

          <span>
            語系
          </span>
        </div>
      </template>

    </Navbar2> -->

    <layout-template>
      <router-view />
    </layout-template>

    <!-- <Footer /> -->

  </div>
</template>

<script>
import 'animate.css'

import { watch } from '@vue/composition-api'
import { $themeBreakpoints, $themeColors } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'

// import Navbar2 from '@/components/Navbar/Navbars2.vue'
// import Footer from '@/components/Footer/Footer.vue'
const LayoutTemplate = () => import('@/layouts/Layout.vue')
// const LayoutFull = () => import('@/layouts/full/Layout.vue')
// const LayoutLeft = () => import('@/layouts/left/Layout.vue')

export default {
  components: {
    // Navbar2,
    // Footer,
    LayoutTemplate,
    // LayoutFull,
    // LayoutLeft
  },
  beforeCreate() {
    document.documentElement.setAttribute('dir', 'ltr')

    // 顏色預加載
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // 瀏覽器大小預加載
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // 圖片預加載
    // const images = [{
    //   name: 'notFoundImg', img: require('@/assets/images/commen/notfound.png'),
    // }, {
    //   name: 'loadingImg', img: require('@/assets/images/commen/loading.png'),
    // }, {
    //   name: 'noImg', img: require('@/assets/images/commen/noImage.svg'),
    // }]
    // // eslint-disable-next-line no-plusplus
    // for (let i = 0, len = images.length; i < len; i++) {
    //   // $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    // }
  },
  mounted() {
    this.supportWebp()
  },
  methods: {
    supportWebp() {
      const isSupportWebp = () => {
        try {
          return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
        } catch (err) {
          return false
        }
      }
      if (isSupportWebp()) {
        store.commit('app/CHECK_SUPPORT_WEBP', true)
        this.$refs.app.classList.add('webp')
      } else {
        store.commit('app/CHECK_SUPPORT_WEBP', false)
        this.$refs.app.classList.add('no-webp')
      }
    },
  },
  setup() {
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width } = useWindowSize()

    watch(width, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })
  },
}
</script>

<style lang="scss" scoped>
</style>
