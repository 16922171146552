import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const { branch } = $themeConfig.app

export default {
  namespaced: true,
  state: {
    beforeLoginRouter: null,
  },
  getters: {},
  mutations: {
    UPDATE_BEFORE_LOGIN_ROUTER_STATE(state, val) {
      state.beforeLoginRouter = val
    },
  },
  actions: {
    // (生成)圖形驗證
    getImgCaptcha(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/captcha', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (登出)
    logout() {
      return new Promise((resolve, reject) => {
        axios('/auth/logout', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (檢查)
    getTokenCheck() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/check', { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)分站金流
    getPaymentData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${branch}/payment`, { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
