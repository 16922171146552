import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const { branch } = $themeConfig.app

export default {
  namespaced: true,
  state: {
    ui: {
      type: {
        domain_new: '網域購買託管',
        domain_trans: '網域攜入',
        domain_continue: '網域續約',
        domain_free: '免費網址',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {
    // (查詢)購物車
    getCartListData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${branch}/cart`, { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (加入)購物車
    setCartCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`${branch}/cart`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)購物車
    setCartUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`${branch}/cart/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)購物車
    setCartListDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`${branch}/cart/${resolveData.id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
