import { $themeBreakpoints, $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    supportWebp: false,
    windowWidth: 0,
    shallShowOverlay: false,
    themeImages: {
      notFoundImg: require('@/assets/images/commen/notfound.png'),
      loadingImg: require('@/assets/images/commen/loading.png'),
      noImg: require('@/assets/images/commen/noImage.svg'),
      infoImg: require('@/assets/images/commen/notifyInfo.png'),
    },
    userData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    currentDeviceType: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.lg) return 'computer'
      return 'mobile'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    CHECK_SUPPORT_WEBP(state, val) {
      state.supportWebp = val
    },
    UPDATE_USERDATA_STATE(state, val) {
      state.userData = val
    },
  },
  actions: {
    // (前往)使用者後台
    linkUserBackend(ctx, resolveData) {
      const { route, type } = resolveData
      if (!route) return
      const { backEndUrl } = $themeConfig.app
      const routeUrl = {
        login: 'login.html',
        register: 'register.html',
        profile: 'profile',
        quota: 'quota',
        cart: 'cart',
        order: 'order',
        'service-domain': 'service/domain',
      }
      let fullUrl = backEndUrl + routeUrl[route]

      if (fullUrl === backEndUrl) fullUrl = backEndUrl + routeUrl.login

      switch (type) {
        case 'replace':
          window.location.replace = fullUrl
          break
        case 'href':
          window.location.href = fullUrl
          break
        case 'blank':
          window.open(fullUrl, '_blank')
          break
        default:
          window.location.href = fullUrl
          break
      }
    },
  },
}
