import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const init = () => {
  const userData = getUserData()
  store.commit('app/UPDATE_USERDATA_STATE', userData)
  useJwt.setToken(localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName))
}

export const updateNavbarInfo = response => {
  if (!response || !response.user_data) return
  const userData = getUserData()
  userData.point = response.user_data.point || 0
  userData.cart_total = response.user_data.cart_total || 0
  localStorage.setItem('userData', JSON.stringify(userData))
  store.commit('app/UPDATE_USERDATA_STATE', userData)
}

// (登出)
export const logout = () => {
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  if (!token) {
    localStorage.clear()
    return
  }
  store.dispatch('api/logout')
  localStorage.clear()
}
