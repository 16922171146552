import Vue from 'vue'
import VueRouter from 'vue-router'
import client from './routes'
import store from '@/store'
import pages from './routes/pages'

import {
  init, isUserLoggedIn, logout, updateNavbarInfo,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...client,
    ...pages,
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (isLoggedIn) {
    store.dispatch('api/getTokenCheck')
      .then(response => {
        init()
        updateNavbarInfo(response.data.data)
      })
      .catch(() => {
        logout()
      })
  }
  // 初始化
  return next()
})

// http://localhost:8082/forget-password.html
// user/cart

// router.beforeResolve((to, from, next) => {
//   next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
