export default [
  {
    path: '/home.html',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      pageTitle: 'Home',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/About.vue'),
    meta: {
      pageTitle: 'about',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/News.vue'),
    meta: {
      pageTitle: 'news',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
    },
  },
]
